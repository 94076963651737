import React from "react"
import st from "../assets/styles/agreements.module.css"

const Agreement = () => {
  return (
    <div className={st.agreements}>
      <div className={st.container}>
        <h1>Пользовательское Соглашение</h1>
        <h2>Определения</h2>
        <p>
          Сервис — Интернет-ресурс, расположенный в сети Интернет по адресу{" "}
          <a href="https://www.appbonus.ru">https://www.appbonus.net.</a> Под
          Сервисом в зависимости от изложения понимается также программное
          обеспечение Сервиса, графическое оформление Сервиса, любой его раздел
          и подраздел, а также информация, размещенная на Сервисе Администрацией
          Сервиса.{" "}
        </p>
        <p>
          Администрация Сервиса — ООО "Джусисофт", которому принадлежат все
          соответствующие исключительные имущественные права на Сервис, включая
          права на доменное имя Сервиса и осуществляющее его администрирование.
        </p>
        <p>
          Пользователь — лицо, зарегистрировавшееся на Сервисе, с помощью номера
          своего мобильного телефона и почты, либо авторизовавшееся с помощью
          своего аккаунта в социальной сети Vkontakte, а также имеющее свой
          Личный кабинет. Для целей Пользовательского соглашения под
          Пользователем понимается также лицо, не зарегистрировавшееся на
          Сервисе, но которое осуществляет доступ к Сервису и/или использует
          и/или использовало его. Любое лицо, осуществляющее доступ к Сервису,
          этим автоматически подтверждает, что оно полностью согласно с
          положениями Пользовательского соглашения, и что в отношении него
          применимы требования, установленные Пользовательским соглашением.
        </p>
        <p>
          Личный кабинет Пользователя — специальный подраздел Сервиса, где
          расположена личная информация о Пользователе, а также другая
          информация, разместить которую может только этот Пользователь. Любой
          Пользователь вправе иметь только один Личный кабинет.
        </p>
        <p>
          Персональные сведения — сведения, размещаемые Пользователем при
          регистрации на Сервисе, в том числе: номер мобильного телефона, email,
          пароль.
        </p>
        <p>
          Любые термины и понятия, использующиеся в Соглашении и не нашедшие
          отражения в разделе «Определения», будут толковаться в соответствии со
          смыслом, вытекающим из текста Пользовательского соглашения. В случае
          возникновения любых разногласий относительно толкования определения,
          используемого в Пользовательском соглашении, применяться будет то
          толкование, которое будет определено Администрацией Сервиса.
        </p>
        <h2>ПРЕДМЕТ И ОБЩИЕ ПОЛОЖЕНИЯ ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ</h2>
        <p>
          Настоящее Пользовательское Соглашение (далее — Соглашение)
          устанавливает правила и условия пользования Сервисом.
        </p>
        <p>
          Регистрация на Сервисе, Доступ к Сервису, использование Сервиса и/или
          совершение любых иных действий на Сервисе Пользователем автоматически
          означает, что Пользователь соглашается с содержанием Пользовательского
          соглашения и обязуется соблюдать все условия настоящего Соглашения.
        </p>
        <p>
          Настоящее Соглашение может быть изменено Администрацией Сервиса (далее
          – Администрацией) в любое время без какого-либо специального
          уведомления. Новая редакция Соглашения вступает в силу с момента ее
          опубликования на Сервисе. Действующая редакция Соглашения всегда
          находится на странице в сети Интернет по адресу:{" "}
          <a href="/agreement">https://www.appbonus.net/agreement</a>.
        </p>
        <h2>РЕГИСТРАЦИЯ НА СЕРВИСЕ</h2>
        <p>
          Чтобы получить доступ к возможностям Сервиса, физическое лицо должно
          пройти процедуру регистрации на Сервисе следующим способом:
        </p>
        <p>
          Написать в регистрационном бланке свой номер телефона в формате
          «792198734521», а также действующий почтовый ящик, в результате чего
          такое лицо становится зарегистрированным Пользователем. Либо пройти
          авторизацию с помощью своего аккаунта в социальной сети Vkontakte.
          Почтовый ящик или аккаунт Vkontakte будет являться логином для доступа
          ко всем возможностям Сервиса.
        </p>
        <p>
          Пользователь обязуется предоставлять правдивые Персональные сведения о
          себе в регистрационном бланке и поддерживать эту Информацию в
          актуальном состоянии. Если эта Информация изменится,
        </p>
        <p>
          Пользователь обязуется как можно скорее сообщить об этом Администрации
          для произведения необходимых изменений.
        </p>
        <p>
          Если лицо предоставляет неверные Персональные сведения или у
          Администрации есть основания полагать, что предоставленная лицом
          регистрационная Информация недостоверна, нарушает условия настоящего
          Соглашения или что лицо использует чужие данные, то Администрация
          имеет право приостановить либо отменить регистрацию и отказать в
          полном доступе ко всем или некоторым возможностям Сервиса и/или
          удалить Личный кабинет.
        </p>
        <p>
          При регистрации на Сервисе Пользователь соглашается получать
          периодические рассылки в виде SMS сообщений на свой мобильный телефон,
          либо рассылки на персональный email Пользователя, который тот указал,
          с предложениями об установке приложений из App Store или Google Play.
        </p>
        <p>
          Пользователь в любой момент имеет право отказаться от получения
          рассылок с предложениями по установке в своем Личном кабинете на
          Сервисе.
        </p>
        <h2>АВТОРИЗАЦИЯ НА СЕРВИСЕ</h2>
        <p>
          Пользователь обязуется не разглашать и не передавать любым третьим
          лицам персональные сведения для доступа к Личному кабинету на Сервисе,
          а также предпринять все необходимые меры для того, чтобы эти данные не
          были доступны любым третьим лицам.
        </p>
        <p>
          Если любое третье лицо помимо Пользователя авторизуется на Сервисе,
          используя персональные сведения Пользователя, то все действия,
          совершенные таким лицом, будут считаться совершенными этим
          Пользователем. Пользователь самостоятельно несет ответственность за
          все действия, совершенные им на Сервисе, а также за все действия,
          совершенные на Сервисе любыми иными лицами с использованием логина и
          пароля Пользователя.
        </p>
        <p>
          Пользователь самостоятельно несет ответственность за сохранность своих
          персональных сведений для доступа к Личному кабинету, а также за все
          последствия, которые могут возникнуть в результате его
          несанкционированного и/или иного использования.
        </p>
        <p>
          В силу настоящего Соглашения, указывая свои персональные данные на
          Сервисе, Пользователь безусловно соглашается:
        </p>
        <ul>
          <li>
            с предоставлением персональных данных неограниченному кругу лиц при
            помощи Сервиса;
          </li>
          <li>с обработкой персональных данных Администрацией;</li>
          <li>с распространением персональных данных при помощи Сервиса;</li>
          <li>
            с иными действиями Администрации в отношении таких данных в связи с
            функционированием Сервиса
          </li>
        </ul>
        <p>
          Администрация не несёт ответственности за возможную потерю или порчу
          сведений, которая может произойти из-за нарушения Пользователем
          положений данной части настоящего Соглашения.
        </p>
        <h2>ИСПОЛЬЗОВАНИЕ СЕРВИСА</h2>
        <p>
          Использование Сервиса Пользователем допускается только в соответствии
          с настоящим Соглашением и исключительно способами, предусмотренными
          техническими возможностями Сервиса.
        </p>
        <p>
          После регистрации Пользователя на Сервисе ему будут приходить
          периодические рассылки в виде SMS сообщения или на личный email с
          предложениями по установке приложений из App Store или Google play.
        </p>
        <p>
          Любой зарегистрированный Пользователь может скачивать приложения
          непосредственно из своего Личного кабинета. Зачисление бонуса
          производится в течение 10 минут — 1 часа только в случае соблюдения
          всех инструкций, указанных в соответствующем разделе Сервиса.
        </p>
        <p>
          Пользователь может вывести зачисленные средства на баланс мобильного
          телефона или QIWI кошелёк, указав нужную сумму в соответствующем поле
          и нажав кнопку «отправить». Данная процедура необратима и не
          предусматривает отмены вывода средств после совершения операции.
        </p>
        <p>
          Пользователь самостоятельно оплачивает все необходимые налоговые
          отчисления с суммы, переведенной на счет мобильного телефона или QIWI
          кошелька.
        </p>
        <p>
          Использование сервиса подразумевает, что Пользователь ознакомился с
          условиями сервиса Яндекс.Деньги{" "}
          <a href="https://money.yandex.ru/pay/doc.xml?offerid=default">
            (https://money.yandex.ru/pay/doc.xml?offerid=default)
          </a>{" "}
          и согласен, что денежное обязательство контрагента перед клиентом
          исполняется путем зачисления соответствующей суммы на электронное
          средство платежа клиента в платежном сервисе Яндекс.Деньги.{" "}
        </p>
        <p>
          Администрация является законным правообладателем и ей принадлежат все
          исключительные имущественные и иные соответствующие права на Сервис в
          полном объеме, в том числе, но, не ограничиваясь перечисленным, на
          программное обеспечение Сервиса, графику, дизайн. Пользователь
          обязуется не размещать в публичном доступе любые материалы, доступные
          в Сервисе, в том числе, но не ограничиваясь: тексты заданий, скриншоты
          интерфейсов.
        </p>
        <p>
          Пользователи обязуются использовать любую информацию, содержащуюся на
          Сервисе только в личных некоммерческих целях, за исключением случаев,
          когда разрешение на иное использование дано Администрацией и/или
          соответствующими правообладателями.
        </p>
        <h2>
          ФУНКЦИОНИРОВАНИЕ СЕРВИСА И ОТВЕТСТВЕННОСТЬ ПРИ ЕГО ИСПОЛЬЗОВАНИИ.
        </h2>
        <p>
          Администрация старается обеспечить надлежащее функционирование Сервиса
          в круглосуточном режиме, однако не несет никакой ответственности, если
          произойдут какие-либо нарушения в работе Сервиса по любым причинам.
        </p>
        <p>
          Администрация не гарантирует, что Информация, размещенная на Сервисе,
          будет доступна в любое время, или не будет удалена или утеряна.
        </p>
        <p>
          Администрация не несет ответственности за сбои и задержки в работе
          Сервиса, а также за возможные последствия таких сбоев и задержек.
        </p>
        <p>
          Администрация не несет ответственности за возможную утечку Информации
          из Базы данных Сервиса по любым причинам, в том числе в результате
          несанкционированных действий третьих лиц.
        </p>
        <p>
          Администрация не несет ответственности за наличие на Сервисе вирусов,
          а также за возможные последствия заражения компьютера Пользователя
          вирусами или попадание на компьютер Пользователя иных вредоносных
          программ.
        </p>
        <p>
          Администрация не отвечает, не возмещает и не несет ответственности за
          любые убытки, включая упущенную выгоду, моральный и иной вред,
          причиненные Пользователю или третьим лицам в результате использования
          ими Сервиса или в связи с функционированием Сервиса, а также во всех
          иных случаях, предусмотренных настоящим Соглашением.
        </p>
        <p>
          Пользователь самостоятельно несет полную ответственность за сообщаемую
          им другим Пользователям или третьим лицам по средством Сервиса
          Информацию, в том числе, за ее содержание и соответствие требованиям
          законодательства РФ, за нарушение прав третьих лиц на размещаемую
          и/или сообщаемую
        </p>
        <p>
          Пользователем Информацию (в том числе, но не ограничиваясь, прав
          автора и/или смежных прав, прав на средства индивидуализации, прав на
          коммерческую тайну и других охраняемых законом прав).
        </p>
        <p>
          Пользователь самостоятельно возмещает любые убытки (в том числе
          упущенную выгоду, моральный и иной вред), возникшие у других
          Пользователей, третьих лиц или Администрации вследствие указанных
          нарушений и/или размещения на Сервисе такой Информации, и/или
          сообщения посредством Сервиса такой Информации, а также совершения
          иных действий в связи с использованием Сервиса.
        </p>
        <h2>ПЕРСОНАЛЬНЫЕ СВЕДЕНИЯ ПОЛЬЗОВАТЕЛЯ</h2>
        <p>
          Администрация оставляет за собой право рассылать SMS сообщения на
          номер пользователя или на личный email, указанный при регистрации,
          пока последний не отпишется от соответствующей рассылки в Личном
          кабинете Пользователя.
        </p>
        <p>
          Администрация обязуется принять все организационные и технические меры
          при обработке персональных сведений Пользователей для их защиты от
          несанкционированного доступа способом, не предусмотренным Сервисом и
          не передавать Персональные сведения третьим лицам без письменного
          согласия Пользователя.
        </p>
        <p>
          Однако не исключено, что в результате сбоя в работе Сервиса, вирусной
          или хакерской атаки, технических неисправностей и иных обстоятельств
          персональные сведения Пользователей могут стать доступными и другим
          лицам. Пользователь это понимает и соглашается с тем, что не будет
          предъявлять претензии к Администрации.
        </p>
        <p>
          Пользователь гарантирует, что использует только собственные данные. В
          свою очередь Сервис обязуется не копировать личные данные
          Пользователей и не использовать их в целях, не связанных с прямым
          функционалом Сервиса.
        </p>
        <p>
          Пользователь обязуется не размещать на Сервисе персональные сведения
          любых третьих лиц, а также не использовать персональные сведения
          других Пользователей каким-либо образом, не соответствующим
          требованиям законодательства РФ, в противоправных или противозаконных
          целях, в целях извлечения выгоды и любых иных целях, не
          соответствующих целям создания Сервиса.
        </p>
        <p>
          Если Пользователь не согласен с вышеуказанными условиями, то он должен
          немедленно прервать процедуру регистрации, либо, если данная процедура
          была завершена – сообщить Администрации для последующего удаления
          Личного кабинета, и использовать Сервис исключительно как
          незарегистрированный Пользователь.
        </p>
        <h2>РАЗРЕШЕНИЕ СПОРОВ</h2>
        <p>
          Пользователь и Администрация Сайта будут пытаться решить все возникшие
          между ними споры и разногласия путем переговоров. В случае
          невозможности разрешить споры и разногласия путем переговоров они
          подлежат рассмотрению в соответствующем суде по месту нахождения
          Администрации.
        </p>
      </div>
    </div>
  )
}

export default Agreement
